const PROD_API_URL = "https://body.avatarx.live/api";
const PROD_SOCKET_URL = "https://body.avatarx.live";

export const API_URL = process.env.NEXT_PUBLIC_API_URL || PROD_API_URL;
export const SOCKET_URL = process.env.NEXT_PUBLIC_SOCKET_URL || PROD_SOCKET_URL;
export const GOOGLE_LOGIN_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_LOGIN_API_KEY ||
  "506153186043-78k060o2s0qsgf9jol950vrucsrp9sli.apps.googleusercontent.com";
export const NEXT_ENV = process.env.NEXT_ENV || "prod";
export const ELEVEN_LABS_X_API_KEY = process.env.NEXT_PUBLIC_ELEVENLABS_API_KEY;
export const VALID_IMAGE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
];

export const plans = {
  monthly: [
    {
      slug: "FREE",
      name: "Free Plan",
      price: {
        US: 0,
        IN: 0,
      },
      subtitle:
        "For users who want to try out the platform before committing to a paid plan",
      details: [
        // "Price: $0/month",
        "Credits: 100 free credits every month",
        "Additional Credits: Pay as You Go rates",
        "Access to basic features",
        "No commitment",
      ],
      isHighlighted: false,
      buttonText: "Current plan",
    },
    {
      slug: "BASIC",
      name: "Basic Plan",
      price: {
        US: 9.99,
        IN: 899,
      },
      subtitle:
        "Ideal for frequent users looking for great value and more features",
      details: [
        // "Price: $9.99/year",
        "Credits: 1200 credits per month",
        "Additional Credits: Pay as You Go rates",
        "Exclusive updates and access to new features",
        "Priority customer support",
      ],
      isHighlighted: false,
      buttonText: "Subscribe to Standard",
    },
  ],
  yearly: [
    {
      slug: "FREE",
      name: "Free Plan",
      price: {
        US: 0,
        IN: 0,
      },
      subtitle:
        "For users who want to try out the platform before committing to a paid plan",
      details: [
        // "Price: $0/year",
        "Credits: 100 free credits every month",
        "Additional Credits: Pay as You Go rates",
        "Access to basic features",
        "No commitment",
      ],
      isHighlighted: false,
      buttonText: "Current plan",
    },
    {
      slug: "BASIC",
      name: "Basic Plan",
      price: {
        US: 80,
        IN: 8630,
      },
      subtitle:
        "Ideal for frequent users looking for great value and more features",
      details: [
        // "Price: $80/year",
        "Credits: 1200 credits per month",
        "Additional Credits: Pay as You Go rates",
        "Exclusive updates and access to new features",
        "Priority customer support",
      ],
      isHighlighted: false,
      buttonText: "Subscribe to Standard",
    },
  ],
};

export const payAsYouGoFeatures = [
  "Access to all basic features",
  "Credits never expire",
  "Purchase more credits anytime",
];

export const BLUR_DATA_URL =
  "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDAFA3PEY8MlBGQUZaVVBfeMiCeG5uePWvuZHI////////////////////////////////////////////////////2wBDAVVaWnhpeOuCguv/////////////////////////////////////////////////////////////////////////wAARCABtAHEDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEDAv/EABUQAQEAAAAAAAAAAAAAAAAAAAAB/8QAGAEBAQEBAQAAAAAAAAAAAAAAAAECAwT/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAMAwEAAhEDEQA/AKA4vQIqKgACANAgi4gCNSAgNSIAKNgHmaEVFBFRQQGoiIqNyAgNSIICgAqNwHkbEVFEAaREquW4CA3EEBQAUAAbgPK0ICwRFRqIiVa5rpEEBoAFAAAAGwDzY0ICiJVRuIiVUbiIA0gAKAAAA1BHBpUEUARqIIqNREAaAAQAAABoIOToAggA0iAKiAKAAgAoAA//2Q==";
